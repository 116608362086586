<template>
  <component :is="component" v-bind="Object.assign({}, $attrs)" v-on="$listeners" ref="componentRef">
    <!-- ref: https://stackoverflow.com/a/50892881/3553367 -->
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </component>
</template>

<script>
export default {
  name: 'WrapperComponent',
  components: {},
  data() {
    return {};
  },
  props: {
    component: {
      required: true,
    },
  },
  computed: {},
  created() {
    // console.log(this.component);
    // console.log(this.$props); //will have missing items if not defined in 'props', so use $attrs to get them
    // console.log(this.$attrs) //does not include items in $props
  },
  methods: {},
};
</script>
